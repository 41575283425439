.flex {
  &-display {
    display: flex;

    &--sm {
      @media (max-width: @media-sm-max) {
        display: flex;
      }
    }

    &--md {
      @media (min-width: @media-md) and (max-width: @media-md-max) {
        display: flex;
      }
    }

    &--lg {
      @media (min-width: @media-lg) {
        display: flex;
      }
    }
  }

  &-display-inline {
    display: inline-flex;
  }

  //Flex direction
  &-direction-row {
    flex-direction: row;
  }

  &-direction-column {
    flex-direction: column;

    &--sm {
      @media (max-width: @media-sm-max) {
        flex-direction: column;
      }
    }

    &--md {
      @media (min-width: @media-md) and (max-width: @media-md-max) {
        flex-direction: column;
      }
    }

    &--md-max {
      @media (max-width: @media-md-max) {
        flex-direction: column;
      }
    }

    &--lg {
      @media (min-width: @media-lg) {
        flex-direction: column;
      }
    }
  }

  &-direction-row-m-column {
    flex-direction: row;

    @media (max-width: @media-sm-max) {
      flex-direction: column;
    }
  }

  &-direction-row-ipad-column {
    flex-direction: row;

    @media (max-width: @media-md) {
      flex-direction: column;
    }
  }

  &-direction-column-m-row {
    flex-direction: column;

    @media (max-width: @media-sm-max) {
      flex-direction: row;
    }
  }

  &-direction-row-reverse {
    flex-direction: row-reverse;
  }

  &-direction-column-reverse {
    flex-direction: column-reverse;

    &--sm {
      @media (max-width: @media-sm-max) {
        flex-direction: column-reverse;
      }
    }
  }

  //Flex 1-5
  .flex-loop(@index) when (@index > 0) {
    &-@{index} {
      flex: @index;
    }
    .flex-loop(@index - 1);
  }

  .flex-loop(5);

  //Flex align-items
  &-align-items-top {
    align-items: flex-start;

    &--xs {
      @media (max-width: @media-sm-max) {
        align-items: flex-start !important;
      }
    }

    &--md {
      @media (min-width: @media-md) and (max-width: @media-md-max) {
        align-items: flex-start !important;
      }
    }
  }

  &-align-items-bottom {
    align-items: flex-end;
  }

  &-align-items-center {
    align-items: center;

    &--xl {
      @media (min-width: @media-xl) {
        align-items: center;
      }
    }

    &--lg {
      @media (min-width: @media-lg) {
        align-items: center;
      }
    }

    &--md {
      @media (min-width: @media-md) and (max-width: @media-md-max) {
        align-items: center;
      }
    }

    &--sm {
      @media (max-width: @media-sm-max) {
        align-items: center;
      }
    }
  }

  &-align-items-baseline {
    align-items: baseline;
  }

  &-align-items-normal {
    align-items: normal;

    &--md {
      @media (min-width: @media-md) and (max-width: @media-md-max) {
        align-items: normal;
      }
    }
  }

  &-align-items-stretch {
    align-items: stretch;
  }

  //Flex align-self
  &-align-self-top {
    align-self: flex-start;
  }

  &-align-self-bottom {
    align-self: flex-end;

    &--sm {
      @media (max-width: @media-sm-max) {
        align-self: flex-end;
      }
    }

    &--xl {
      @media (min-width: @media-xl) {
        align-self: flex-end;
      }
    }
  }

  &-align-self-center {
    align-self: center;
  }

  &-align-self-baseline {
    align-self: baseline;
  }

  &-align-self-stretch {
    align-self: stretch;
  }

  //Justify Content

  &-justify-space-between {
    justify-content: space-between;

    @media (min-width: @media-lg) {
      &--lg {
        justify-content: space-between !important;
      }
    }

    @media (min-width: @media-md) and (max-width: @media-md-max) {
      &--md {
        justify-content: space-between;
      }
    }

    @media (max-width: @media-sm-max) {
      &--sm {
        justify-content: space-between !important;
      }
    }
  }

  &-justify-content-end {
    justify-content: flex-end;

    @media (min-width: @media-lg) {
      &--lg {
        justify-content: flex-end !important;
      }
    }

    @media (min-width: @media-md) and (max-width: @media-md-max) {
      &--md {
        justify-content: flex-end;
      }
    }
  }

  &-justify-space-evenly {
    justify-content: space-evenly;
  }

  &-justify-space-around {
    justify-content: space-around;
  }

  &-justify-end {
    justify-content: flex-end;

    @media (max-width: @media-sm-max) {
      &--sm {
        justify-content: flex-end;
      }
    }
  }

  &-justify-center {
    justify-content: center;

    @media (max-width: @media-sm-max) {
      &--sm {
        justify-content: center;
      }
    }

    @media (min-width: @media-md) and (max-width: @media-md-max) {
      &--md {
        justify-content: center;
      }
    }

    @media (min-width: @media-lg) {
      &--lg {
        justify-content: center;
      }
    }
  }

  &-justify-start {
    justify-content: flex-start;

    &--sm-max {
      @media (max-width: @media-sm-max) {
        justify-content: flex-start;
      }
    }

    &--md-max {
      @media (max-width: @media-md-max) {
        justify-content: flex-start;
      }
    }
  }

  //Wrap Content

  &-nowrap {
    flex-wrap: nowrap;
  }

  &-wrap {
    flex-wrap: wrap;
  }

  &-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  // Flex Flow
  &-flow-col-wrap {
    flex-flow: column wrap;
  }

  &-flow-row-wrap {
    flex-flow: row wrap;
  }

  &-flow-row-nowrap {
    flex-flow: row nowrap;
  }
}
