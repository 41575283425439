@spacing-data: {
  --0: 0;
  --2: 0.125rem;
  --4: 0.25rem;
  --8: 0.5rem;
  --12: 0.75rem;
  --16: 1rem;
  --24: 1.5rem;
  --28: 1.75rem;
  --32: 2rem;
  --40: 2.5rem;
  --48: 3rem;
  --56: 3.5rem;
  --64: 4rem;
  --80: 5rem;
  --96: 6rem;
  --112: 7rem;
};

each(@spacing-data, {
  .pt@{key} {padding-top: @value !important;}
  .pb@{key} {padding-bottom: @value !important;}
  .pl@{key} {padding-left: @value !important;}
  .pr@{key} {padding-right: @value !important;}
  .mt@{key} {margin-top: @value !important;}
  .mb@{key} {margin-bottom: @value !important;}
  .ml@{key} {margin-left: @value !important;}
  .mr@{key} {margin-right: @value !important;}
  .p@{key} {padding: @value !important;}
  .m@{key} {margin: @value !important;}
  .ph@{key} {padding-left: @value !important; padding-right: @value !important;}
  .mh@{key} {margin-left: @value !important; margin-right: @value !important;}
  .pv@{key} {padding-top: @value !important; padding-bottom: @value !important;}
  .mv@{key} {margin-top: @value !important; margin-bottom: @value !important;}
});

each(@spacing-data, {
  @media(min-width: @media-lg) and (max-width: @media-xl) {
    .pt-lg@{key} {padding-top: @value !important;}
    .pb-lg@{key} {padding-bottom: @value !important;}
    .pl-lg@{key} {padding-left: @value !important;}
    .pr-lg@{key} {padding-right: @value !important;}
    .mt-lg@{key} {margin-top: @value !important;}
    .mb-lg@{key} {margin-bottom: @value !important;}
    .ml-lg@{key} {margin-left: @value !important;}
    .mr-lg@{key} {margin-right: @value !important;}
    .p-lg@{key} {padding: @value !important;}
    .m-lg@{key} {margin: @value !important;}
    .ph-lg@{key} {padding-left: @value !important; padding-right: @value !important;}
    .mh-lg@{key} {margin-left: @value !important; margin-right: @value !important;}
    .pv-lg@{key} {padding-top: @value !important; padding-bottom: @value !important;}
    .mv-lg@{key} {margin-top: @value !important; margin-bottom: @value !important;}
  }
});

each(@spacing-data, {
  @media(min-width: @media-md) and (max-width: @media-md-max) {
    .pt-md@{key} {padding-top: @value !important;}
    .pb-md@{key} {padding-bottom: @value !important;}
    .pl-md@{key} {padding-left: @value !important;}
    .pr-md@{key} {padding-right: @value !important;}
    .mt-md@{key} {margin-top: @value !important;}
    .mb-md@{key} {margin-bottom: @value !important;}
    .ml-md@{key} {margin-left: @value !important;}
    .mr-md@{key} {margin-right: @value !important;}
    .p-md@{key} {padding: @value !important;}
    .m-md@{key} {margin: @value !important;}
    .ph-md@{key} {padding-left: @value !important; padding-right: @value !important;}
    .mh-md@{key} {margin-left: @value !important; margin-right: @value !important;}
    .pv-md@{key} {padding-top: @value !important; padding-bottom: @value !important;}
    .mv-md@{key} {margin-top: @value !important; margin-bottom: @value !important;}
  }
});

each(@spacing-data, {
  @media(max-width: @media-sm-max) {
    .pt-sm@{key} {padding-top: @value !important;}
    .pb-sm@{key} {padding-bottom: @value !important;}
    .pl-sm@{key} {padding-left: @value !important;}
    .pr-sm@{key} {padding-right: @value !important;}
    .mt-sm@{key} {margin-top: @value !important;}
    .mb-sm@{key} {margin-bottom: @value !important;}
    .ml-sm@{key} {margin-left: @value !important;}
    .mr-sm@{key} {margin-right: @value !important;}
    .p-sm@{key} {padding: @value !important;}
    .m-sm@{key} {margin: @value !important;}
    .ph-sm@{key} {padding-left: @value !important; padding-right: @value !important;}
    .mh-sm@{key} {margin-left: @value !important; margin-right: @value !important;}
    .pv-sm@{key} {padding-top: @value !important; padding-bottom: @value !important;}
    .mv-sm@{key} {margin-top: @value !important; margin-bottom: @value !important;}
  }
});
