.active-underline() {
  &:after {
    content: '';
    display: block !important;
    height: 2px;
    width: 32px;
    background: @astral;
    position: absolute;
    left: calc(50% - 16px);
    top: 32px;
  }

  svg {
    path {
      fill: @astral !important;
    }
  }

  .contact-us__dropdown-title--active {
    color: @astral;
  }
}

.rotate-icon() {
  svg {
    transform: rotate(180deg);
  }
}

.overlay-styles(@top-position) {
  background: @white;
  z-index: 22;
  top: @top-position !important;
  box-shadow: 1px 5px 15px rgba(0, 0, 0, 0.05);
}
